import Mock from "../mock";

const database = {
  information: {
    name: "Roland Aroche",
    aboutContent:
      "I am a software developer. Most of all I enjoy solving problems while discovering the unique feeling of providing an elegant solution to such problems. Programming languages are my tool of choice to build everything from web apps to enterprise software solutions.",
    age: null,
    phone: "",
    nationality: "",
    language: "English, Spanish",
    email: "roland (dot) aroche (at)  gmail (dot) com",
    address: "South Florida",
    freelanceStatus: "",
    socialLinks: {
      facebook: "",
      twitter: "",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/roland-aroche/",
      dribbble: "",
      github: "https://github.com/raroche",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: null,
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/Roland-Aroche-Resume-2020.pdf",
  },
  services: [
    {
      title: "Software Development",
      icon: "code",
      details:
        "I like to keep up to date with the latest technologies and best practices to build quality software. I build Desktop and web applications, backend system, and custom projects.",
    },
    {
      title: "Consulting",
      icon: "seo-consulting",
      details:
        "With the experience and knowledge, I have acquired in the industry I am able to coach and help companies develop the best and most optimal software solution for their e-commerce needs.",
    },
    {
      title: "E-Commerce",
      icon: "shopping-basket",
      details:
        "I have been helping companies succeed in the e-commerce space for more than 7 years by developing the right software and implementing strategies to grow sales on many platforms and marketplaces.",
    },
  ],
  reviews: [],
  skills: [
    {
      title: "Java",
      value: 90,
    },
    {
      title: "Spring Framework",
      value: 80,
    },
    {
      title: "Javascript",
      value: 85,
    },
    {
      title: "Python",
      value: 85,
    },
    {
      title: "C",
      value: 80,
    },
    {
      title: "React",
      value: 80,
    },
    {
      title: "SQL",
      value: 90,
    },
    {
      title: "E-commerce",
      value: 95,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Geek Text",
      subtitle: "Software Engineering 1 Class Project",
      tech: "Spring Boot, React, PostgresSQL",
      date: "2019",
      imageUrl: "/images/geek-textSmall.jpg",
      largeImageUrl: [
        "/images/geek-text-book-detail-large.jpg",
        "images/geek-text-author-large.jpg",
      ],
      url: "https://geek-text.netlify.com",
    },
    {
      id: 2,
      title: "Five Oceans",
      subtitle: "E-commerce Website.",
      tech: "Shopify Liquid Language, JavaScript, HTML, CSS",
      date: "2016-2020",
      imageUrl: "/images/FiveOceansSmall.jpg",
      largeImageUrl: ["/images/FiveOceansLarge.jpg"],
      url: "https://www.five-oceans.com",
    },
    {
      id: 3,
      title: "eBay HTML Template Generator",
      subtitle: "Java Desktop App.",
      tech: "Java, Swing, OOP, MySQL, FTP",
      date: "2017",
      imageUrl: "/images/eBaySmall.jpg",
      largeImageUrl: ["/images/GUI.jpg", "/images/HTML-Template.png"],
      url: "https://youtu.be/-Ef2xf_yn38",
    },
    {
      id: 4,
      title: "Robotc Project",
      subtitle: "Follow The Line",
      tech: "C++",
      date: "2016",
      imageUrl: "/images/robotSmall.jpg",
      largeImageUrl: ["/images/robot.jpg"],
      url: "https://youtu.be/Znwd35pEsLw",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "Software Developer",
        company: "Baron USA",
        details: `Developed internal software to support the e-commerce operation and increase customer engagement. 
        | Developed and maintained the company Shopify e-commerce website using JavaScript, Shopify Liquid, HTML, and CSS
        | Participated in the integration of a legacy Java system with Amazon, Walmart, eBay, and Shopify APIs.
        | Developed and maintained a Java Applications to automate the generation of eBay HTML product description templates, persisting the data in AWS RDS for PostgreSQL and images in S3. 
        |Currently developing a Membership Rewards Program using React and Node.js |
        Collaborated with Business Stakeholders to capture requirements and provide guidance to deliver the best results. |
        Performed SEO best practices and optimized the website for better performance.
        `,
      },
      {
        id: 2,
        year: "2016 - 2018",
        position: "Ecommerce Manager",
        company: "Baron USA",
        details: `Developed and implemented ecommerce strategy in order to grow sales and brand awareness.  
        | Overview of the entire e-commerce business operation and report to the CEO.
        | Researched market in order to discover new trends and technologies in order to improve website performance 
        | Managed e-commerce seller accounts for different marketplaces such as Amazon, eBay, and Walmart.
        | Ensured that the website delivers a great customer experience.`,
      },
      {
        id: 3,
        year: "2015 - 2016",
        position: "Ecommerce Specialist",
        company: "MVP Marine",
        details: `Supported company’s ecommerce strategy, designed, and developed frictionless customer interactions.
        | Identified areas for opportunity and improvement within the ecommerce section of the site.
        | Developed and maintained the company e-commerce website (Magento)
        | Managed advertisement campaigns in Amazon Ads and Google Ads.`,
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2017 - 2020",
        graduation: "Bachelor of Science in Computer Science",
        university: "Florida International University (FIU)",
        details: "GPA: 3.86",
      },
      {
        id: 2,
        year: "2014 - 2017",
        graduation: "Associate in Computer Science",
        university: "Miami Dade College",
        details: null,
      },
    ],
  },
  blogs: [],
  contactInfo: {
    phoneNumbers: ["Username: roland.aroche"],
    emailAddress: ["roland (dot) aroche (at)  gmail (dot) com"],
    address: "South Florida, USA",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
